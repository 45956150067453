import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase, kebabCase} from 'change-case';
import {ButtonPrimary, useStyles, InputCurrency, warningIcon, Icon} from '@ace-de/ui-components';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsFixedRateServiceTypes, apsPriceTypes} from '@ace-de/eua-entity-types';
import {useTranslate} from '@computerrock/formation-i18n';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import getPrice from '../../utils/getPrice';
import config from '../../config';

const EditFixedRatesData = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand, activeLocale} = useTranslate();
    const translateModal = createTranslateShorthand('edit_fixed_rates_modal');
    const {confirmUpdateFixedRates, serviceFixedPrices} = props;
    const {pricePeriodId} = useSearchQueryParams();

    const inflationSubsidy = serviceFixedPrices?.find(fixedPrice => (
        fixedPrice.serviceType === apsFixedRateServiceTypes.INFLATION_SUBSIDY
    ))?.value;
    const fuelSubsidy = serviceFixedPrices?.find(fixedPrice => (
        fixedPrice.serviceType === apsFixedRateServiceTypes.FUEL_SUBSIDY
    ))?.value;

    const [fixedPrices, setFixedPrices] = useState({
        [apsFixedRateServiceTypes.INFLATION_SUBSIDY]:
            inflationSubsidy ? getPrice(inflationSubsidy, activeLocale, {showCurrency: false}) : '',
        [apsFixedRateServiceTypes.FUEL_SUBSIDY]:
            fuelSubsidy ? getPrice(fuelSubsidy, activeLocale, {showCurrency: false}) : '',
    });
    const [errorMessage, setErrorMessage] = useState('');

    const handleOnClick = () => {
        confirmUpdateFixedRates({
            pricePeriodId: parseInt(pricePeriodId, 10),
            prices: Object.entries(fixedPrices).map(([key, value]) => {
                return {
                    value: parseFloat(value),
                    type: apsPriceTypes.SERVICE_FIXED_PRICE,
                    serviceType: key,
                };
            }),
        });
    };

    const handleOnChange = (serviceType, value) => {
        setFixedPrices({
            ...fixedPrices,
            [serviceType]: value,
        });
    };

    const validateFields = useCallback(() => {
        if (!fixedPrices[apsFixedRateServiceTypes.INFLATION_SUBSIDY]
            || !fixedPrices[apsFixedRateServiceTypes.FUEL_SUBSIDY]) {
            setErrorMessage(translateModal('error_message.all_fields_must_be_populated'));
            return;
        }

        if (Object.values(fixedPrices).some(field => field === `${config.NOT_ALLOWED_PRICE_VALUE}`)) {
            setErrorMessage(translate('global.error_message.zero_values_not_allowed'));
            return;
        }

        setErrorMessage('');
    }, [setErrorMessage, fixedPrices, translate, translateModal]);

    useEffect(() => {
        validateFields();
    }, [validateFields]);

    return (
        <div className={cx('global!ace-u-margin--32-0')}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="fixed-rates-service">
                            {translateModal('table_header.service')}
                        </TableCell>
                        <TableCell qaIdentPart="fixed-rates-price">
                            {translateModal('table_header.price')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {serviceFixedPrices.length > 0 && serviceFixedPrices.map(fixedPrice => {
                        const {serviceType} = fixedPrice;

                        return (
                            <TableRow key={serviceType}>
                                <TableCell qaIdentPart={`${kebabCase(serviceType)}-service`}>
                                    {translateModal(`table_cell.${serviceType.toLowerCase()}`)}
                                </TableCell>
                                <TableCell qaIdentPart={`${kebabCase(serviceType)}-price`}>
                                    <InputCurrency
                                        name={camelCase(serviceType)}
                                        currencySign={translate('global.currency.eur')}
                                        className={cx('ace-c-number-input--small')}
                                        value={fixedPrices[serviceType] || ''}
                                        onChange={value => handleOnChange(serviceType, value)}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {errorMessage && (
                <p
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {errorMessage}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-margin--top-32',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',

                ])}
            >
                <ButtonPrimary
                    onClick={handleOnClick}
                    className={cx('global!ace-u-margin--right-32')}
                    isDisabled={!!errorMessage}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

EditFixedRatesData.propTypes = {
    confirmUpdateFixedRates: PropTypes.func.isRequired,
    serviceFixedPrices: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        serviceFixedPrices: state.priceManagement.serviceFixedPrices,
    });
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateFixedRates: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFixedRatesData);
