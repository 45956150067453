import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import getPrice from '../../utils/getPrice';
import generateTariffDetails from '../generateTariffDetails';

const RatesByTimePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('rates_by_time_panel');
    const {initiateUpdateRatesByTime, timePrices, isEditingDisabled} = props;

    return (
        <Panel
            title={(
                <div>
                    {translatePanel('title.rates_by_time')}
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        {translatePanel('subtitle.calculated_every_five_minutes')}
                    </p>
                </div>
            )}
            actions={
                !isEditingDisabled && (
                    <InteractiveIcon
                        icon={editIcon}
                        onClick={() => initiateUpdateRatesByTime({contentType: apsPriceTypes.TIME_PRICE})}
                    />
                )
            }
            className={cx('ace-c-panel--full-bleed-content')}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="rates-by-time-day" colSpan={2}>
                            {translatePanel('table_header.day')}
                        </TableCell>
                        <TableCell qaIdentPart="rates-by-time-from">
                            {translatePanel('table_header.from')}
                        </TableCell>
                        <TableCell qaIdentPart="rates-by-time-to">
                            {translatePanel('table_header.to')}
                        </TableCell>
                        <TableCell qaIdentPart="rates-by-time-price">
                            <div className={cx('global!ace-u-typography--align-right')}>
                                {translatePanel('table_header.price')}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timePrices.length > 0 && timePrices.map((timePrice, idx) => {
                        const {id, value} = timePrice;
                        const tariffDetails = generateTariffDetails(timePrice.tariff);
                        return (
                            <TableRow
                                key={`${id}-${idx}`}
                                qaIdentPart={id}
                            >
                                <TableCell
                                    qaIdentPart="time-price-day"
                                    qaIdentPartPostfix={id}
                                    colSpan={2}
                                >
                                    {translate(`global.day.${tariffDetails?.days[0].toLowerCase()}`) || '-'}
                                </TableCell>
                                <TableCell qaIdentPart="time-price-from">
                                    {tariffDetails?.timeSlot[0] || '-'}
                                </TableCell>
                                <TableCell qaIdentPart="time-price-to">
                                    {tariffDetails?.timeSlot[1] || '-'}
                                </TableCell>
                                <TableCell
                                    qaIdentPart="time-price-value"
                                    qaIdentPartPostfix={id}
                                >
                                    <div className={cx('global!ace-u-typography--align-right')}>
                                        {value ? getPrice(value, activeLocale) : '-'}
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Panel>
    );
};

RatesByTimePanel.propTypes = {
    initiateUpdateRatesByTime: PropTypes.func.isRequired,
    timePrices: PropTypes.array.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        timePrices: state.priceManagement.timePrices,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateRatesByTime: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatesByTimePanel);
