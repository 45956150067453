import {call, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import modalIds from '../../modalIds';
import updatePricesByTimePeriod from './updatePricesByTimePeriod';

const updatePricesFlow = function* updatePricesFlow() {
    while (true) {
        const {payload} = yield take(priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW);
        yield* openModal(modalIds.EDIT_PRICES, {contentType: payload.contentType});

        const chosenModalOption = yield take([
            priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
            priceManagementActionTypes.DECLINE_UPDATE_PRICES,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === priceManagementActionTypes.CONFIRM_UPDATE_PRICES
        ) {
            const {pricePeriodId, prices} = chosenModalOption.payload;
            yield* closeModal(modalIds.EDIT_PRICES, {contentType: payload.contentType});
            yield call(updatePricesByTimePeriod, {
                payload: {pricePeriodId, prices},
            });
            continue;
        }

        yield* closeModal(modalIds.EDIT_PRICES, {contentType: payload.contentType});
    }
};

export default updatePricesFlow;
